import { graphql } from "gatsby";
import * as React from "react";
import {
  CurvedSectionDivider,
  CurvedSectionDividerGlow,
  Gutter,
  HelmetSeo,
  Layout,
  PageMaxWidth,
  Section,
  SectionCustomerLogos,
  SectionIntegrations,
  SimpleHero,
} from "../components";

type Props = {
  data: GatsbyTypes.TechnologyIntegrationsPageQuery;
};

export const query = graphql`
  query TechnologyIntegrationsPage {
    prismicTechnologyIntegrationsPage {
      data {
        hero_title
        hero_paragraph {
          raw
        }
        hero_contact_button_label
        hero_calculator_button_label

        customers_title
        customers_description {
          raw
        }
        customers_button_label

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

export default function TechnologyIntegrationsPage({ data }: Props) {
  const page = data.prismicTechnologyIntegrationsPage?.data;
  if (!page) throw new Error("page missing");

  return (
    <Layout>
      <HelmetSeo {...page} />

      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <SimpleHero
            title={page.hero_title}
            description={page.hero_paragraph}
            contactButtonLabel={page.hero_contact_button_label}
            calculatorButtonLabel={page.hero_calculator_button_label}
          />
          <Gutter size={4} />
        </PageMaxWidth>
      </Section>

      <CurvedSectionDividerGlow
        position="top"
        glowColor="orange"
        backColor="light1"
      />
      <SectionIntegrations background="dark" />
      <CurvedSectionDivider
        position="bottom"
        frontColor="newDark2"
        backColor="light1"
      />

      <SectionCustomerLogos
        title={page.customers_title}
        description={page.customers_description}
        buttonLabel={page.customers_button_label}
      />
    </Layout>
  );
}
